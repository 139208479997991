$white-color: white;

$black-color: #1A1C1F;
$background-black-color: #1A1C1FB3; 
$background-grey-color: #1A1C1F1A;

$grey-color: #8D949D;
$grey-line-color: #afafaf;
$grey-border-color: #f5f4f5;
$grey-gradient-color: #DBDBDB;
$bubble-border-color: #707070;
$statistics-grey: #505254;
$faded-border-color: #70707054;
$ask-nevo-grey: #EBEBEC;
$light-grey-color: #F3F4F5;
$new-border-grey: #C4C4C4;
$weird-grey: #4D4D4D;
$border-grey-color: #DEDEDE;
$grey-header-color: #9EA0A0;

$ev-benefits-grey: #4F5154;

$blue-color: #0FE0D1;
$border-blue-color: #10e0d0;
$blue-hover-color: #0dbfb3;
$blue-statement-color: #10DFD0;
$dark-blue: #0dbfb3;

$link-color: #0046FF;
$bank-of-ireland-blue: #1900fa;
$green-color: #17DE92;
$red-color: #EA4335;
$red-label-color: #AA1317;

// LeasePlan Colors
$orange-color: #F05327;
$orange-text-color: #ED411E;
$orange-border-color: #F15527;
$orange-on-grey-color: #DC582D;

// EV Talk Show
$evs-black-color: #000;
$evs-black-line: #1A1C20;
$evs-grey-color: #464747;
$evs-blue-color: #20DFD0;

// Nevo-BOI
$nevo-boi-blue-color: #0000ff;
$nevo-boi-dark-blue-color: #000066;
$nevo-boi-dark-button-main-color: #0000C2;
$nevo-boi-dark-button-shadow-color: #00067B;
$nevo-boi-light-color: #eceef6;
$nevo-boi-dark-grey-color: #333333;
$nevo-boi-light-grey-color: #666666;
$nevo-boi-lighter-grey-color: #979797;
$nevo-boi-lightest-grey-color: #dcdcdc;