@import "../../styles/colors.scss";
@import "../../styles/constants.scss";

@keyframes show-header {
	0% {
		transform: translateY(-100%);
	}
	100% {
		transform: translateY(0);
	}
}
@keyframes hide-header {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(-100%);
	}
}

.hideHeader {
	animation: hide-header 0.5s ease-in;
}

.hideHeaderBoi {
	.hideHeader {
		@media (max-width: 768px) {
		  .hideHeader {
			display: none;
		  }
		}
	  }
}

.showHeader {
	display: flex !important;
	animation: show-header 0.5s ease-out;
}

.nevoLogo,
.hamburgerHolder,
.subNavButtonHolder > p {
	cursor: pointer;
	user-select: none;
}

.nevoLogo {
	position: relative;
	bottom: 19px;

	@media screen and (max-width: $small-screen) {
		bottom: 16px;
	}
}

.navButton {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;

	.coolUnderline {
		position: relative;
		top: 8px;

		min-width: 22px;
		width: 1.65vw;
		max-width: 30px;

		max-height: 3px;
		height: 0.165vw;
		min-height: 2px;

		background-color: transparent !important;

		user-select: none;

		@media screen and (max-width: $small-screen) {
			top: 6.5px;
		}
	}
	.displayUnderline {
		background-color: $blue-color !important;
	}

	.subNavButtonHolder {
		max-width: 25px;

		display: flex;
		flex-flow: column nowrap;
		align-items: center;

		padding-top: 40px;
		padding-bottom: 40px;

		p:first-child {
			margin-top: 0;
		}
		p {
			margin-top: 20px;
			font-size: 16px;
			font-weight: 400;

			white-space: nowrap;
		}

		@media screen and (max-width: $small-screen) {
			padding-top: 34px;
			padding-bottom: 34px;

			p {
				margin-top: 16px;
				font-size: 14px;
			}
		}
	}
}

.headerContainer {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;

	display: flex;
	justify-content: space-between;

	padding-top: 27px;
	padding-left: 14vw;
	padding-right: 14vw;

	overflow-y: hidden;
	width: 100vw;
	height: 75px;

	transition: background-color 0.3s, height 0.3s;

	background: $light-grey-color;

	@media screen and (max-width: $small-screen) {
		height: 60px;
		padding-top: 21px;
		padding-left: 10%;
		padding-right: 10%;
	}
	@media screen and (max-width: $mobile-screen) {
		padding-left: 5%;
		padding-right: 5%;
	}

	.desktopNav {
		display: flex;
		width: 67.2%;
		max-width: 928px;
		justify-content: space-between;

		.navButton {
			color: $black-color;
		}
	}

	.hamburgerHolder {
		display: flex;
		justify-content: flex-end;
		width: 120px;

		@media screen and (max-width: $small-screen) {
			width: 107px;
		}

		.hamburgerIcon {
			min-width: 16px;
			width: 1vw;
			max-width: 18px;

			min-height: 16px;
			height: 1vw;
			max-height: 18px;
		}
	}
}

.headerContainerOpenned,
.fixedHeader {
	max-height: unset !important;
	background: $light-grey-color !important;

	.navButton {
		.nevoLogo,
		span,
		span > a,
		.subNavButtonHolder > p {
			color: $black-color !important;
		}
		.subNavButtonHolder > p[data-color="blue"] {
			color: $blue-color !important;
			font-weight: 500;
		}
	}
}
.fixedHeader {
	box-shadow: 0 -6px 10px 5px rgba($color: $black-color, $alpha: 0.5);
}

.transparentHeader {
	background: transparent;

	.desktopNav {
		.navButton {
			span,
			.subNavButtonHolder > p {
				color: $white-color;
			}
		}
	}
}

.blackHeader {
	background-color: rgba($color: $black-color, $alpha: 0.5);
}

.blackStyle {
	.desktopNav {
		.navButton {
			span,
			.subNavButtonHolder > p {
				color: $black-color;
			}
		}
	}
}

.navCharging {
	.navChargingSpan {
		.navChargingA {
			color: $blue-color !important;
		}
	}
}

// styling for BOI version
.navButtonBoi {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	color: $nevo-boi-blue-color;

	.coolUnderlineBoi {
		position: relative;
		top: 12px;

		min-width: 55px;
		width: 13vw;
		max-width: 130px;

		max-height: 5px;
		height: 0.5vw;

		color: $nevo-boi-dark-blue-color;
		background-color: transparent !important;

		user-select: none;

		@media screen and (max-width: $small-screen) {
			top: 6.5px;
		}
	}
	.displayUnderlineBoi {
		background-color: $nevo-boi-dark-blue-color !important;
	}

	.subNavButtonHolderBoi {
		max-width: 25px;

		display: flex;
		flex-flow: column nowrap;
		align-items: center;

		padding-top: 40px;
		padding-bottom: 40px;

		p:first-child {
			margin-top: 0;
		}
		p {
			margin-top: 10px;
			font-family: "Open Sans", sans-serif;
			font-size: 16px;
			font-weight: 400;

			white-space: nowrap;
		}

		@media screen and (max-width: $small-screen) {
			padding-top: 34px;
			padding-bottom: 34px;

			p {
				font-family: "Open Sans", sans-serif;
				margin-top: 16px;
				font-size: 14px;
			}
		}
	}

	@media screen and (max-width: $mobile-screen) {
		display: none;
	}

	@media screen and (max-width: $tablet-screen) {
		display: none;
	}

	@media screen and (max-width: $big-tablet-screen) {
		display: none;
	}
}

.containerBoi {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 100;
}

.bodyBoi {
	height: 100%;
}

.headerContainerBoi {
	position: fixed;
	top: 130px; // This should match the height of .containerBoi
	left: 0;
	right: 0;
	z-index: 90;

	display: flex;
	justify-content: center;
	gap: 50px;

	padding-top: 27px;
	padding-left: 20vw;
	padding-right: 20vw;

	overflow-y: hidden;
	width: 100vw;
	height: 75px;

	transition: background-color 0.3s, height 0.3s;

	background: $white-color;
	color: $nevo-boi-blue-color;

	@media screen and (max-width: $mobile-screen) {
		display: none;
	}

	@media screen and (max-width: $tablet-screen) {
		display: none;
	}

	@media screen and (max-width: $big-tablet-screen) {
		display: none;
	}

	.hamburgerHolderBoi {
		margin-right: 0;
	}

	.desktopNavBoi {
		display: flex;
		width: 90%;
		max-width: 928px;
		justify-content: space-evenly;

		.navButton {
			color: $nevo-boi-blue-color;
		}
	}

	.hamburgerHolderBoi {
		display: flex;
		justify-content: flex-end;
		width: 120px;

		@media screen and (max-width: $small-screen) {
			width: 107px;
		}

		.hamburgerIconBoi {
			min-width: 16px;
			width: 1vw;
			max-width: 18px;

			min-height: 16px;
			height: 1vw;
			max-height: 18px;
		}
	}
}

.linkBoi {
	font-size: 20px !important;
	color: $nevo-boi-blue-color;
	font-family: "Open Sans", sans-serif;
	transition: color 0.3s ease;
}

.navButtonBoi .linkBoi.selected {
	color: $nevo-boi-dark-blue-color;
}

.navButtonBoi .linkBoi:hover {
	color: $nevo-boi-dark-blue-color;
}

.navButtonBoi .linkBoi.selected:hover {
	color: $nevo-boi-dark-blue-color !important;
}

.headerContainerOpennedBoi,
.fixedHeaderBoi {
	max-height: unset !important;
	background: $white-color !important;
	color: $nevo-boi-blue-color;
	z-index: 100;

	.navButtonBoi {
		.nevoLogo,
		span,
		span > a,
		.subNavButtonHolderBoi > p {
			color: $nevo-boi-blue-color !important;
		}
		.subNavButtonHolderBoi > p[data-color="blue"] {
			color: $nevo-boi-blue-color !important;
			font-weight: 500;
		}
	}
}

.transparentHeaderBoi {
	background: transparent;
	color: $nevo-boi-blue-color;

	.desktopNavBoi {
		.navButtonBoi {
			span,
			.subNavButtonHolderBoi > p {
				font-size: 20px;
				font-family: "Open Sans", sans-serif;
				color: $nevo-boi-blue-color;
			}
		}
	}
}

.blackHeaderBoi {
	background-color: $white-color;
	color: $nevo-boi-dark-blue-color;
}

.blackStyleBoi {
	.desktopNavBoi {
		.navButtonBoi {
			span,
			.subNavButtonHolderBoi > p {
				color: $nevo-boi-dark-blue-color;
			}
		}
	}
}

.evShowBanner {
	position: absolute;
	top: 130px; // Adjust this value based on the combined height of BoiHeader and main header
	left: 0;
	right: 0;
	z-index: 89;
	text-align: center;
}
